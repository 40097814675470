.openBazaarContainer {
    display: grid;
    grid-template-columns: 49% 49%;
    gap: 10px;
    padding: 10px;
    align-content: center;
    justify-content: center;
    align-items: center;
}

.linkGameComponentLink {
    width: 100%;
}

.openBazaarContainer__child {
    display: flex;
    flex-direction: column;
    align-items: start;
    /* padding: 10px; */
    padding-bottom: 0px;
    border: none;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(79, 79, 79, 1);
    -moz-box-shadow: 0px 0px 1px 0px rgba(79, 79, 79, 1);
    box-shadow: 0px 0px 1px 0px rgba(79, 79, 79, 1);
    border-radius: 15px;
}

.openBazaarContainer__child a {
    width: 100%;
}

.openBazarxPadding {
    padding: 10px;
}



.openBazaarContainer__child button {
    width: 100%;
    border-bottom-left-radius: 15px;
    border: none;
    border-bottom-right-radius: 15px;
    color: #fff;
    padding: 10px;
}

.openBazarBaziName {
    font-weight: 600;
    font-size: 1.1rem;
}

.a-href--button-openBazaar {
    width: 100% !important;
}
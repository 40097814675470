.scanner-image {
    width: 100%;
    padding: 0px 100px;
    margin: 20px 0px;
}

.scanner-image img {
    width: 100%;
    object-fit: cover;
}

.addFund-content {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 20px 0px;
}

.addFund-content h1 {
    font-weight: 600;
}

.fund-add-detail-p {
    color: #b1b0b0;
    padding: 0px 50px;
    margin-bottom: 40px !important;
}

.addFund-content form {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
}

.addFund-content form button {
    width: 80%;
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: var(--color-primary);
    color: #fff;
    font-weight: 600;
    font-size: 1rem;
    margin-top: 20px;
}

.addFund-content form label {
    width: 80%;
}

.addFund-content form label input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
}

.balance-account--withdraw-fund {
    width: 100%;
    padding: 20px;
    border: none;
    border-radius: 15px;
    color: #fff;
    background-color: var(--color-primary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.balance-account--withdraw-fund p {
    font-size: 1rem;
    font-weight: 500;
}

.balance-account--withdraw-fund button {
    padding: 10px;
    font-size: 1rem;
    background-color: var(--color-secondary);
    border: none;
    color: #fff;
    border-radius: 10px;
}

.content-withdraw-page--fund-show {
    width: 100%;
    padding: 10px 20px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.withdrawFundHeading {
    margin-top: 20px;
}
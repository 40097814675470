.game-archive {
    display: flex;
    flex-direction: column;
    align-items: center;
    min-width: 100%;
    justify-content: center;
    height: 500px;

    /* background-color: ; */
}

.game-archive__title {
    font-size: 2rem;
    margin-bottom: 20px;
    position: relative;
    font-weight: 500;
    color: var(--color-primary);
}

.game-archive__title::after {
    content: '';
    visibility: visible;
    width: 150px;
    position: absolute;
    height: 3px;
    background-color: #c0c0c0;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%);

}

.game-archive__content {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 10px;
}

.game-archive--patti {
    color: #000;
    text-decoration: none;
    /* border: 1px solid black; */
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 60%;
    border: 1px solid #c0c0c0;
    border-radius: 20px;
}

.game-archive--patti svg {
    width: 30px !important;
    height: 40px !important;
}

.game-archive--patti p {
    font-size: 1.5rem;

}

.game-archive--singlePatti {
    background: rgb(0, 243, 255);
    background: -moz-radial-gradient(circle, rgba(0, 243, 255, 1) 0%, rgba(0, 191, 255, 1) 60%);
    background: -webkit-radial-gradient(circle, rgba(0, 243, 255, 1) 0%, rgba(0, 191, 255, 1) 60%);
    background: radial-gradient(circle, rgba(0, 243, 255, 1) 0%, rgba(0, 191, 255, 1) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00f3ff", endColorstr="#00bfff", GradientType=1);
}

.game-archive--triplePatti {
    background: rgb(255, 254, 0);
    background: -moz-radial-gradient(circle, rgba(255, 254, 0, 1) 0%, rgba(255, 190, 0, 1) 60%);
    background: -webkit-radial-gradient(circle, rgba(255, 254, 0, 1) 0%, rgba(255, 190, 0, 1) 60%);
    background: radial-gradient(circle, rgba(255, 254, 0, 1) 0%, rgba(255, 190, 0, 1) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#fffe00", endColorstr="#ffbe00", GradientType=1);
}
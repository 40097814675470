#imgProfilePic {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    border: 1px solid black;
    object-fit: cover;
}

.profile-details {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;
}

.profile-details p {
    font-family: poppins;
    font-weight: 500;
    font-size: 1rem;
    margin: 0px;
}

.profile-details button {
    padding: 10px;
    border-radius: 15px;
    background-color: var(--color-primary);
    border: none;
    color: #fff;
    font-weight: 600;
    margin-bottom: 30px;
}

.accordions {
    padding: 10px;
}

.icontext-profile {
    gap: 10px;
}

.icontext-profile p {
    margin: 0px !important;
}

.link-NOSTYLE {
    text-decoration: none !important;
    color: var(--color-secondary);
}

.acountlinkchild {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid var(--color-primary);
    border-radius: 20px;
    margin: 5px 10px;
}

.icontext-profile p {
    font-size: 1.2em;
}

.icontext-profile svg {
    height: 20px;
}

.logoutService {
    width: 80%;
    align-self: center;
    padding: 10px;
    border-radius: 20px;
    border: none;
    background-color: red;
    color: #fff;
    font-weight: 600;
    margin-top: 30px;
}
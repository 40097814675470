.button-0849,
.button-08491 {
    padding: 10px 20px !important;
    width: 100%;
    height: 60px;
    border-radius: 10px;
    border: none;
    font-size: 1.2rem;
    font-weight: 500;

}

.button-0849 {
    background: rgb(2, 168, 23);
    background: linear-gradient(45deg, rgba(2, 168, 23, 1) 0%, rgba(3, 36, 0, 1) 100%);
    color: #fff;
}

.button-08491 {
    background: rgb(96, 2, 2);
    background: linear-gradient(45deg, rgba(96, 2, 2, 1) 0%, rgba(255, 0, 0, 1) 100%);
    color: #fff;
}
.security-header-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    padding: 10px;
}

.security-page-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* height: 600px; */
    padding-top: 30px;
    gap: 20px;
}

.security-header-content h2 {
    font-weight: 600;
    font-size: 2rem;
    color: var(--color-secondary);
}

.security-header-content p {
    padding: 5px 20px;
    font-size: .7rem;
    color: #9d9d9d;
}

.security-content-label {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
}

.security-content-label p {
    font-size: 1rem;
    color: #222;
    font-weight: 600;
}

.security-content-label input {
    width: 100%;
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
}

.security-content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 10px 50px;
    width: 100%;
    gap: 10px;
}

.changePasscode {
    background-color: red;
    color: #fff;
    font-weight: 600;
    margin-top: 10px;
    font-size: 1.1rem;
    border: none;
    padding: 10px;
    border-radius: 10px;
}
.pattilist-content {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.h1PattiList {
    color: var(--color-secondary);
    width: 100%;
    text-align: center;
}

.pattiListP {
    font-size: 14px !important;
    text-align: center;
    padding: 30px;
    padding-top: 10px;
    color: #a9a9a9;
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;
}

.pattilist-dets {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
}

.patti-list-child {
    width: 95%;
    padding: 5px;
    background-color: var(--color-third);
    color: #fff;
    border-radius: 10px;
    border: none;
    gap: 10px;
    display: grid;
    grid-template-columns: 12% 12% 12% 12% 12% 12% 12%;
}

.patti-list-child p {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    border: 1px solid #fff;
    font-size: 1.1rem;
    font-weight: 600;
}
@font-face {
  font-family: poppins;
  src: url("https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
}

@font-face {
  font-family: raleway;
  src: url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100..900;1,100..900&display=swap");
}

@font-face {
  font-family: inter;
  src: url("https://fonts.googleapis.com/css2?family=Inter:wght@100..900&display=swap");
}

:root {
  --color-primary: #008dda;
  --color-secondary: #0e46a3;
  --color-third: #65B741;
}

body::-webkit-scrollbar {
  display: none;
}

* {
  padding: 0px;
  font-family: poppins;
  font-size: .9rem;
  margin: 0px;
  list-style-type: none;
  text-decoration: none;
  box-sizing: border-box;
  scrollbar-width: none;

}

p {
  margin: 0px;
}

.displayFlexRow {
  flex-direction: row;
  display: flex;
  align-items: center;
}

.displayFlexColumn {
  flex-direction: column;
  display: flex;
}

.centerthebutton {
  justify-content: center;
  flex-direction: row;
  display: flex;
  align-items: center;
}

p {
  margin-bottom: 0px !important;
  /* display: flex; */
}

.inTable-Yellow-Color td {
  background-color: red !important;
  color: #fff !important;
}
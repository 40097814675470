.gamepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    padding: 20px;
}

.game-button-group {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 10px;
}

.gamePage-Component {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;
    -webkit-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    box-shadow: 0px 0px 9px 0px rgba(0, 0, 0, 0.75);
    /* padding: 10px; */
    border-radius: 15px;

    background: rgb(0, 174, 255);
    background: radial-gradient(circle, rgba(0, 174, 255, 1) 0%, rgba(2, 0, 36, 1) 98%);
}

.gamePage-Component button,
.gamePage-Component a button {
    width: 100%;
    padding: 10px;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
    font-family: poppins;
    font-weight: 500;
    border: none;
}

.gamePage-Component a {
    width: 100%;
    border-bottom-left-radius: 15px;
    border-bottom-right-radius: 15px;
    color: #fff;
    font-family: poppins;
    font-weight: 500;
    border: none;
}


.gamePage-Component-child {
    display: flex;
    flex-direction: column;
    align-items: start;
    width: 100%;

    padding: 10px;
    border-radius: 15px;
}

.gamePage-Component-child {
    color: #fff !important;
}

.componentGame--iconText {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.componentGame--iconText b {
    letter-spacing: 1px;
}

.gameTitle {
    font-size: 1.2rem;
    letter-spacing: 1px;
}

.game-content-plce {
    width: 100%;
    gap: 10px;
    display: flex;
    flex-direction: column;

    padding: 5px;
}

.game-number-buttons {
    display: grid;
    width: 100%;
    grid-template-columns: 18% 18% 18% 18% 18%;
    /* padding: 10px; */
    /* margin: 5px; */
    gap: 10px;
    /* overflow: hidden; */
    box-sizing: border-box;
}

.game-number-buttons button {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 15px;
}

.gameInputPlace {
    display: flex;
    flex-direction: row;
    justify-content: start;
    align-items: center;
    overflow: hidden;
    flex-wrap: wrap;
    gap: 10px;
    padding: 10px 0px;
}

.gameInputPlace button {
    background-color: var(--color-secondary);
    color: #fff;

    padding: 5px 10px;
    border-radius: 10px;
    border: none;
}

.gameInputPlace input {
    width: 40%;
    border: 1px solid #c0c0c0;
    /* outline: 1px solid var(--color-primary); */
    padding: 5px 10px;
    border-radius: 15px;
}

.play-game-button {
    padding: 10px;
    border-radius: 10px;
    background-color: var(--color-third);
    color: #fff;
    font-weight: 600;
    border: none;
}

.single-patti-heading {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 30px;
    margin-bottom: 20px;
}

.gameInputPlace.triple {
    display: flex;
    flex-direction: column;
}

.gameInputTripleSpan-child {
    height: 40px;
    width: 40px;
    border: 1px solid;
    font-family: inter;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    border-radius: 10px;
    font-size: 1.2rem;
}

.gameInputTripleSpan {
    display: flex;
    flex-direction: row;
    gap: 10px;

}

.buttonDelete {
    background-color: red;
    padding: 5px;
    border-radius: 10px;
    border: none;
    color: #fff;
}

.quick-amount-buttons {
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
}

.quick-amount-buttons button {
    padding: 10px;
    border-radius: 10px;
    border: none;
    background-color: #fff;
    font-weight: 600;
    border: 1px solid var(--color-primary);
    box-shadow: 5px 5px 0px 0px var(--color-secondary);
}

.quickAmounts {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    font-size: 1rem;
    font-weight: 600;
}

.pattilist-guide {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin: 10px 0px;
}

.patti-list-button {
    background: rgb(255, 229, 0);
    background: -moz-radial-gradient(circle, rgba(255, 229, 0, 1) 0%, rgba(255, 173, 0, 1) 60%);
    background: -webkit-radial-gradient(circle, rgba(255, 229, 0, 1) 0%, rgba(255, 173, 0, 1) 60%);
    background: radial-gradient(circle, rgba(255, 229, 0, 1) 0%, rgba(255, 173, 0, 1) 60%);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffe500", endColorstr="#ffad00", GradientType=1);
    border-radius: 15px;
    font-size: 1.1rem;
    border: none;
    color: red;
    padding: 10px 20px;
    cursor: pointer;
    margin-bottom: 20px;
}

.patti-list--div-triple {
    display: grid;
    grid-template-columns: repeat(10, 1fr);
    width: 100%;
    gap: 1px;
}

.patti-list-button-item-div {
    border: none;
    width: 100%;
    display: flex;
    justify-content: center;
    gap: 2px;
}

.patti-list-button-item {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    padding: 5px;
    margin: 1px;
    width: 100%;
    border: none;
    border-radius: 0px;
    cursor: pointer;
    border-radius: 5px;
    animation: colorFade 1s infinite alternate;
}

.patti-list-button-item.purple {
    background-color: purple;
    color: white;
}

.patti-list-button-item.red {
    background-color: red;
    color: white;
}

.patti-list-button-item.green {
    background-color: green;
    color: white;
}

.patti-list-button-item.blue {
    background-color: var(--color-primary);
    color: white;
}

@keyframes colorFade {
    0% {
        opacity: 1;
    }

    100% {
        opacity: 0.7;
    }
}
.bet-history-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 20px;
}

.bet-history-head-container {
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(0, 212, 255, 1) 0%, rgba(9, 9, 121, 1) 93%);
    width: 100%;
    border: none;
    border-radius: 20px;
    color: #fff;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px 0px;
    gap: 10px;
}

.search-inputs {
    width: 100%;
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    place-items: center;
}

.search-inputs label {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.search-inputs label input {
    width: 90%;
}

#search-bet-results {
    background-color: var(--color-secondary);
    color: #fff;
    padding: 10px 20px;
    font-weight: 600;
    border: none;
    border-radius: 10px;
    margin: 10px;
}

.bet-history-table {
    width: 100%;
}



.inTable-Yellow-Color td {
    background-color: red !important;
    color: #fff !important;
}

.amountInvstDetails {
    width: 100%;
    margin: 10px;
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(9, 9, 121, 1) 35%, rgba(0, 212, 255, 1) 100%);
    border-radius: 20px;
    border: 0px;
    color: #fff;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.add-withdraw-container {
    display: grid;
    grid-template-columns: 50% 50%;
    justify-content: center;
    padding: 10px;
    gap: 10px;
}

.gameContainer-onlyOne {
    display: flex;
    gap: 10px;
    padding: 10px;
}

.gameContainer-onlyOne-child.kalyanifatafat {
    background-color: var(--color-primary) !important;
}

.gameContainer-onlyOne-child.kolkataFatafat {
    background: rgb(255, 229, 0);
    background: radial-gradient(circle, rgba(255, 229, 0, 1) 0%, rgba(255, 173, 0, 1) 60%);
}

.gameContainer-onlyOne-child {
    width: 100%;

    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 30px;
    border-radius: 10px;
    border: none;
    font-size: 1.5rem;
    font-weight: 600;
    color: #fff;
}

.homeHeadings {
    font-size: 1.3rem;
    font-weight: 600;
    padding-left: 10px;
    position: relative;
    color: green;
    margin-bottom: 10px !important;
}

.homeHeadings::after {
    content: '';
    visibility: visible;
    height: 2px;
    width: 50px;
    background-color: green;
    position: absolute;
    bottom: 0;
    left: 10px;
}

.marquee-Text {
    background-color: yellow;
    font-size: 1rem;
    color: red;
    font-weight: 600;
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 5px;
    width: 80%;
    max-width: 500px;
    text-align: center;
}

.modal-close-button {
    background: none;
    border: none;
    font-size: 1.5rem;
    position: absolute;
    top: 10px;
    right: 10px;
    cursor: pointer;
}

.modal-patti-list {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
    margin-top: 20px;
}

.modal-patti-button {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 5px;
    background-color: #f0f0f0;
    cursor: pointer;
}
.nav-wrapper {
    height: 80px;
    background-color: var(--color-secondary);
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    position: sticky;
    top: 0;
    width: 100%;
    z-index: 1000;
}

.link-style-none {
    color: #fff !important;
    text-decoration: none !important;
}

.logoContainer img {
    height: 50px;
}

.walletContainer {
    display: flex;
    flex-direction: row;
    gap: 10px;
    align-items: center;
}

.walletContainer svg {
    height: 20px;
    color: #fff;
    align-items: center;
}

.walletBalancewithIcon {
    color: #fff;
    display: flex;
    flex-direction: row;
    gap: 5px;
    font-family: poppins;
}

.footerNavbar {
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 10px 20px;
    padding-top: 20px;
    background-color: var(--color-secondary);
    color: #fff;
    border-top-left-radius: 30px;
    border-top-right-radius: 30px;
    z-index: 1000;
    /* Ensure it's above other content */
}

.footerNavbar__bottom-child {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.footerHere {
    margin-top: 80px;
}
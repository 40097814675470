.quick-link {
    display: flex;
    flex-direction: column;
    align-items: center;

}

.quick-links {
    display: flex;
    flex-direction: row;
    gap: 10px;
    overflow-x: auto;
    /* Enable horizontal scrolling */
    /* padding-bottom: 10px; */
    padding-left: 10px;
}

.quick-links::-webkit-scrollbar {
    display: none;
}

.quick-link .link button {
    height: 75px;
    width: 75px;
    border-radius: 50%;
    border: none;
    color: #fff;
    background-color: var(--color-primary);
}

.title {
    text-align: center;
}
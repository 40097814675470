.login-page {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

#login-illustration {
    width: 100%;
}

.login-page .login-header {
    font-size: 2rem;
    color: var(--color-secondary);
    margin-top: 30px;
    font-weight: 600;
    margin-bottom: 0px;
}

.cred-group {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

#credButton {
    width: 80%;
    margin: 10px 0px;
    padding: 10px;
    border-radius: 10px;
    background-color: var(--color-primary);
    color: #fff;
    font-weight: 600;
    border: none;

}

.cred-group label {
    width: 80%;
    display: flex;
    flex-direction: column;

}

.cred-group label p {
    font-size: 1rem;
    font-weight: 600;
    margin-top: 10px !important;
    margin-bottom: 5px !important;
}

.cred-group label input {
    padding: 10px;
    border-radius: 10px;
    border: 1px solid #c0c0c0;
}

.loginCred-group,
.registerCred-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 70%;
    padding: 10px;
    border-radius: 15px;
    font-weight: 600;
    margin: 5px 0px;
    font-size: 1rem;
}

.style-welcome {
    color: #a0a0a0;
    width: 80%;
    text-align: center;
    margin: 10px 0px 20px 0px !important;
}

.loginCred-group {
    background-color: #fff;
    color: var(--color-secondary);
    border: 1px solid var(--color-secondary);
}

.registerCred-group {
    background-color: var(--color-primary);
    color: #fff;
    border: 1px solid var(--color-primary);
}

.linkInCred {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: center;
    text-decoration: none;
    color: unset;
}
/* table.css */

.table-wrapper {
    overflow-x: auto;
    padding: 10px;
}

.fl-table {
    width: 100%;
    border-collapse: collapse;
    background: var(--color-third);
}

.fl-table thead th {
    color: #fff;
    font-weight: bold;
    text-align: center;
    border: 1px solid #fff;
}

.fl-table tr:nth-child(even) {
    background-color: lightgrey;
}

.fl-table tr:nth-child(odd) td {
    background-color: var(--color-primary);
}

.fl-table tbody td {
    color: #fff;
    text-align: center;
    padding: 10px 2px;
    border: 1px solid #fff;
    background-color: var(--color-secondary);
}

.accordion-item {
    margin: 10px;
    background-color: var(--color-primary) !important;
    padding: 10px;
    border-radius: 20px;
    text-align: center;
    color: #fff !important;
}

.accordion-content {
    border-radius: 10px;
    border: 2px solid white !important;
    background: rgb(2, 0, 36);
    background: radial-gradient(circle, rgba(2, 0, 36, 1) 0%, rgba(0, 212, 255, 1) 0%, rgba(9, 9, 121, 1) 93%);
}
@keyframes toRight {
    from {
        transform: rotate(0);
    }

    to {
        transform: rotate(360deg);
    }
}

@keyframes time {
    from {
        transform: translate(61%, 0%) rotate(0);
    }

    to {
        transform: translate(61%, 0%) rotate(360deg);
    }
}

@keyframes toLeft {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(-360deg);
    }
}

.preloader {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

.preloader__mesage {
    color: var(--color-secondary);
    font-weight: normal;
    text-align: center;
    margin-top: 20px;
    font-size: 18px;
}

.preloader__img-container {
    height: 260px;
    width: 260px;
    position: relative;
}

.preloader__item {
    height: 250px;
    width: 250px;
    border-radius: 50%;
    box-sizing: border-box;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.preloader__item:after {
    content: '';
    height: 100%;
    width: 100%;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    border-radius: 50%;
    border: 6px solid #2d9cdb;
    clip-path: inset(0 0 50% 0);
    z-index: 1;
    animation: toRight 2s infinite linear;
}

.preloader__item--mid {
    height: 230px;
    width: 230px;
}

.preloader__item--mid:after {
    border-color: #66b4e6;
    border-width: 4px;
    z-index: 2;
    animation: toLeft 2s infinite linear .3s;
}

.preloader__item--inner {
    height: 210px;
    width: 210px;
}

.preloader__item--inner:after {
    border-color: #99cbea;
    border-width: 3px;
    z-index: 3;
    transform: rotate(-45deg);
}

.preloader__clock {
    width: 170px;
    height: 170px;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 4;
    left: 0;
    border-radius: 50%;
}

.clock {
    transform: rotate(-29deg);
}

.clock__center {
    display: block;
    height: 10px;
    width: 10px;
    background-color: var(--color-primary);
    border-radius: 50%;
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    transform: translate(5px);
}

.clock__dots {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
    z-index: 1;
    height: 1px;
    width: 100%;
    border-left: 3px solid var(--color-primary);
    border-right: 3px solid var(--color-primary);
}

.clock__dots:nth-of-type(1),
.clock__dots:nth-of-type(4n) {
    border-left: 8px solid var(--color-secondary);
    border-right: 8px solid var(--color-secondary);
}

.clock__dots:nth-of-type(1) {
    transform: rotate(30deg);
}

.clock__dots:nth-of-type(2) {
    transform: rotate(60deg);
}

.clock__dots:nth-of-type(3) {
    transform: rotate(90deg);
}

.clock__dots:nth-of-type(4) {
    transform: rotate(120deg);
}

.clock__dots:nth-of-type(5) {
    transform: rotate(150deg);
}

.clock__dots:nth-of-type(6) {
    transform: rotate(180deg);
}

.clock:after,
.clock:before {
    content: '';
    display: block;
    background-color: var(--color-secondary);
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    margin: auto;
}

.clock:after {
    height: 5px;
    width: 45%;
    right: 5%;
    transform-origin: left center;
    animation: time 12s infinite linear;
}

.clock:before {
    height: 2px;
    width: 45%;
    right: 5%;
    transform-origin: left center;
    animation: time 0.6s infinite linear;
}
.slider {
    overflow-x: auto;
    /* Enable horizontal scrolling */
    white-space: nowrap;
    /* Prevent wrapping of slides */
    -webkit-overflow-scrolling: touch;
    /* Smooth scrolling for iOS */
}

.slider::-webkit-scrollbar {
    display: none;
}

.slider-container {
    position: relative;
}

.slide {
    display: inline-block;
    /* Display slides in a row */
    width: 100%;
    /* Each slide takes up the full width of the container */
}

.slide img {
    width: 100%;
}

.dots {
    text-align: center;
    margin-top: 10px;
    position: absolute;
    bottom: 10px;
    left: 50%;
    transform: translateX(-50%);
}

.dot {
    display: inline-block;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #bbb;
    margin: 0 5px;
    cursor: pointer;
}

.dot.active {
    background-color: #222;
}
* {
    font-size: 12px;
    font-family: poppins;
}

#gamename-gamedate-print {
    font-size: 14px;
    font-weight: 600;
    font-family: poppins;
}

td,
th,
tr,
table {
    border-top: 1px solid black;
    border-collapse: collapse;
}

td.description,
th.description {
    width: 75px;
    max-width: 75px;
}

td.quantity,
th.quantity {
    width: 60px;
    max-width: 70px;
    word-break: break-all;
}



.centered {
    text-align: center;
    align-content: center;
}

.ticket {
    width: 155px;
    max-width: 155px;

    display: flex;
    flex-direction: column;
    align-items: center;

}

img {
    max-width: inherit;
    width: inherit;
}

@media print {

    .hidden-print,
    .hidden-print * {
        display: none !important;
    }
}